
import { defineComponent } from 'vue';
import NewsBox from '../../../components/Frontend/NewsBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';



export default defineComponent({
  meta: {
    de: {
      title: 'Logos, Fakten, Media | Styria Media Group',
      keywords: ['presskit','logo','medien','styria media group','graz'],
      description: 'Das Pesskit der Styria Media Group – jetzt downloaden.',
      image: require('@/assets/img/og/startseite.png'),
      
    },
    en: {
      title: 'logos, facts, media | Styria Media Group',
      keywords: ['press kit','logo','facts','styria media group','graz'],
      description: 'The Styria Media Group pesskit - download now.',
      image: require('@/assets/img/og/startseite.png'),
    },
  },
components: {
  NewsBox,
  Footer,

  },
  setup() {
 
   return {
    
     mdiChevronUp,
 
   };
 },
  
});
