<style lang="scss">
#debug {
  display:none;
}
#frontend {
  .presskit-all {
    .header-section-box {
      display:none;
    }
    .outer-presskit {
      margin-bottom:100px;
    .inner-prop {
      margin-top: 20px;
      border: 10px solid #ccc;
      padding: 30px;
      position: relative;
      transition: $transition-std;
      .txt {
        text-align: center;
        h2 {
          margin-top: 30px;
          font-family: 'American Typewriter ITC W04 Md';
          text-transform: none;
          font-size: 180%;
          padding: 0 40px;
        }
      }
      span {
        position: absolute;
        width: 100%;

        bottom: 30px;
        left: 0;
        display: block;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: $letter-spacing-std;
        @media (max-width: $width-md) {
          position: relative;
          margin-top: 60px;
          bottom: 0;
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }
    .height-500 {
      height: 500px;
      @media (max-width: $width-md) {
        height: auto;
      }
    }
    .height-400 {
      height: 400px;
      @media (max-width: $width-md) {
        height: auto;
      }
    }
    .height-300 {
      height: 300px;
      @media (max-width: $width-md) {
        height: auto;
      }
    }
    .b-coral {
      border-color: $coral;
      color: $coral;
    }
    .b-wine-red {
      border-color: $wine-red;
    }
    .b-blue-60per {
      border-color: $blue-60per;
      color: $coral;
    }
    .b-mint {
      border-color: $mint;
    }
    .b-violet {
      border-color: $violet-50per;
      background-color: $violet-50per;
      color: #fff;
    }
    .b-sand {
      border-color: $sand;
      background-color: $sand;
    }
  }
   
  }
}
  

</style>

<template>
<div class="presskit-all">

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="headline-box-std">
          <div class="headline-inner">
            <h1>{{ $t('presskit.hl') }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container outer-presskit">
      <!-- NO SEARCH NO RESULTS -->
      <div class="row align-self-end justify-content-center">
        <div class="col-lg-4 col-md-6 col-10 align-self-end">
          <div class="inner-prop height-300 b-sand">
            <div class="txt">
              <h2>{{ $t('General.management') }}</h2>
            </div>
            <span>{{ $t('General.ueber-uns') }}</span>
            <router-link :to="{ name: 'management' }" class="stretched-link"></router-link>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-10 align-self-start ">
          <div class="inner-prop height-400 b-blue-60per">
            <div class="txt">
              <h2>{{ $t('General.styrianner-werden') }}</h2>
            </div>
            <span>{{ $t('General.karriere') }}</span>
            <router-link :to="{ name: 'styrianerIn-werden' }" class="stretched-link"></router-link>
          </div>
        </div>
      </div>
      <div class="row align-self-start justify-content-center">
        <div class="col-lg-3 col-md-6 col-10 align-self-start">
          <div class="inner-prop height-300 b-coral">
            <div class="txt">
              <h2>{{ $t('General.media') }}</h2>
            </div>
            <span>{{ $t('General.brands') }}</span>
            <router-link :to="{ name: 'media' }" class="stretched-link"></router-link>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-10 align-self-start">
          <div class="inner-prop height-500 b-wine-red">
            <div class="txt">
              <h2>{{ $t('General.was-uns-ausmacht') }}</h2>
            </div>
            <span>{{ $t('General.ueber-uns') }}</span>
            <router-link :to="{ name: 'was-uns-ausmacht' }" class="stretched-link"></router-link>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-10 align-self-start">
          <div class="inner-prop height-400 b-violet">
            <div class="txt">
              <h2>{{ $t('General.offene-positionen') }}</h2>
            </div>
            <span>{{ $t('General.karriere') }}</span>
            <router-link :to="{ name: 'offene-positionen' }" class="stretched-link"></router-link>
          </div>
        </div>
      </div>
      
    </div>   
   <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="headline-box-std">
          <div class="headline-inner">
            <h1>LATEST<br />NEWS</h1>
          </div>
        </div>
      </div>
    </div>
  </div>  
<NewsBox />
</div>
<Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NewsBox from '../../../components/Frontend/NewsBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';



export default defineComponent({
  meta: {
    de: {
      title: 'Logos, Fakten, Media | Styria Media Group',
      keywords: ['presskit','logo','medien','styria media group','graz'],
      description: 'Das Pesskit der Styria Media Group – jetzt downloaden.',
      image: require('@/assets/img/og/startseite.png'),
      
    },
    en: {
      title: 'logos, facts, media | Styria Media Group',
      keywords: ['press kit','logo','facts','styria media group','graz'],
      description: 'The Styria Media Group pesskit - download now.',
      image: require('@/assets/img/og/startseite.png'),
    },
  },
components: {
  NewsBox,
  Footer,

  },
  setup() {
 
   return {
    
     mdiChevronUp,
 
   };
 },
  
});
</script>
